import React from "react"
import { Helmet } from "react-helmet"
import { GlobalStyle } from "styles/globalStyle"

function Head() {
  return (
    <>
      <Helmet>
        <link
          href={`https://${process.env.GATSBY_WP_DOMAIN}/core/wp-content/plugins/gravityforms/legacy/css/readyclass.min.css?ver=2.5.6`}
          rel="stylesheet"
          media="print"
          onLoad="this.onload=null;this.removeAttribute('media');"
        />
      </Helmet>
      <GlobalStyle />
    </>
  )
}

export default Head
