import React from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import { FaChevronDown } from "react-icons/fa"
import { Link } from "gatsby"
import vars from "styles/variables"
import { mobileNavItemVariant } from "variants/mobileNavItemVariant"
import { convertToInternalUrl } from "utils/globalUtil"

function MobileNavItem({
  item,
  mobileNavClose,
  handleActiveItem,
  activeSlug,
  ...rest
}) {
  const isItemActive = item.slug === activeSlug && true
  return (
    <motion.div variants={mobileNavItemVariant} {...rest}>
      <StyledMobileLinkContainer
        $isItemActive={isItemActive}
        className="d-flex align-items-center"
      >
        <div className="flex-grow-1">
          <StyledMobileLink
            to={convertToInternalUrl(item.url)}
            onClick={mobileNavClose}
          >
            {item.label}
          </StyledMobileLink>
        </div>
        <StyledChevronContainer>
          {item.childItems?.nodes?.length > 0 && (
            <FaChevronDown onClick={() => handleActiveItem(item.slug)} />
          )}
        </StyledChevronContainer>
      </StyledMobileLinkContainer>
    </motion.div>
  )
}

export default MobileNavItem

const StyledMobileLinkContainer = styled.div`
  color: ${props => (props.$isItemActive ? vars.secondary : vars.white)};
  padding-right: 6px;

  a {
    color: ${props => (props.$isItemActive ? vars.secondary : vars.white)};
  }

  svg {
    transition: ease-in-out 300ms;
    transform: ${props =>
      props.$isItemActive ? "rotate(180deg)" : "rotate(0)"};
  }
`

const StyledMobileLink = styled(Link)`
  font-size: 1rem;
  padding: 0.8rem 0;
  display: inline-block;
`
const StyledChevronContainer = styled.div`
  font-size: 18px;
`
