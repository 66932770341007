import React from "react"
import GravityFormForm from "@inspired-agency/gatsby-gravityforms-component"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { lighten } from "polished"
import { toast } from "react-toastify"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"
import vars from "styles/variables"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { find } from "lodash-es"

function Form({ data, handleFormSuccess, location }) {
  const { allGfForm } = useStaticQuery(
    graphql`
      query {
        allGfForm {
          edges {
            node {
              ...GravityFormComponent
            }
          }
        }
      }
    `
  )

  const { name, email } = location?.state || {}

  const handleSuccessInternal = ({ values, confirmations, dataType }) => {
    // Get the form based on it's id
    const formEntity = find(allGfForm.edges, {
      node: { formId: Number(data.type) },
    })

    // Get the form slug
    const { slug } = formEntity?.node || {}
    console.log("form slug", slug)

    // Track event in GA
    trackCustomEvent({
      category: "form",
      action: "submission",
      label: slug || "Unknown",
    })

    toast.success(
      (confirmations instanceof Array && confirmations[0]?.message) ||
        "Thank you, we will be in touch soon."
    )

    // Currently there's no way for us to set property names or pass back the form name or id in Gravity Forms
    // instead we can only use the key value pairs passed back from the API. In this case
    // input 7 is the hidden field named 'Redirect To Additional Enquiry', if this field is present
    // and has a value of "redirect", then send the user to the additional enquiry form.
    if (values.input_7 === "redirect") {
      const data = {
        state: {
          name: values.input_1,
          email: values.input_4,
        },
      }
      navigate("/additional-enquiry", data)
    }
  }

  return (
    <Container className="mb-4">
      <Row>
        <Col>
          <StyledFormContainer>
            <GravityFormForm
              id={Number(data.type)}
              presetValues={{ input_5: name, input_6: email }}
              formData={allGfForm}
              successCallback={props => {
                // Callback function passed to component
                if (typeof handleFormSuccess === "function")
                  handleFormSuccess({ ...props })

                // Internal function which runs on every form submission
                const dataType = Number(data.type)
                handleSuccessInternal({ ...props, dataType })
              }}
              captchaSize="normal"
              enablePostcodeSoftware={false}
              enableCompactAddress={false}
              enableLeadTracking={true}
            />
          </StyledFormContainer>
        </Col>
      </Row>
    </Container>
  )
}

export default Form

const StyledFormContainer = styled.div`
  margin-left: -15px;
  margin-right: -15px;

  @media (max-width: ${vars.screen_xs_max}) {
    margin: 0;
  }

  .gravityform {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        margin-bottom: 15px !important;
      }
    }

    label {
      margin-bottom: 0.5rem;
      display: inline-block;
    }

    .gfield_visibility_hidden {
      display: none;
    }

    .gravityform__field,
    .gravityform__postcode_software,
    .gform_footer {
      padding-right: 15px;
      padding-left: 15px;
      position: relative;

      @media (max-width: ${vars.screen_xs_max}) {
        padding: 0;
      }
    }

    .gravityform__field__input {
      width: 100%;
    }

    .gravityform__field__input,
    .gravityform__postcode_software_textfield {
      height: 44px;
      padding: 0 15px;
    }

    .gravityform__field__input__textarea {
      height: 100px;
      padding: 15px;
    }

    .gform_footer {
      text-align: right;
    }

    .gravityform__button,
    .gravityform__postcode_software_button {
      min-width: 230px;
      display: inline-block;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
      line-height: 1;
      border: none;
      color: ${vars.white};
      opacity: 1;
      transition: ease-in-out 200ms;
      background: ${vars.secondary};
      padding: 15px;

      &:hover {
        background: ${lighten(0.05, vars.secondary)};
      }

      &:disabled,
      &[disabled] {
        opacity: 0.5;
      }
    }

    .gravityform__postcode_software_button {
      background: ${vars.blue};
      &:hover {
        background: ${lighten(0.05, vars.blue)};
      }

      &.gravityform__postcode_software_button_enter_manually {
        background: ${vars.lightBlue};
        color: ${vars.primary};
        &:hover {
          background: ${lighten(0.05, vars.lightBlue)};
        }
      }
    }

    .gravityform__postcode_software_container {
      position: relative;
      .gravityform__postcode_software_input_group {
        display: flex;
        align-items: center;

        @media (max-width: ${vars.screen_md_max}) {
          display: block;

          button {
            display: inline-block;
            min-width: 50%;
          }
        }

        .gravityform__postcode_software_textfield {
          width: 100%;
        }
      }
      .gravityform__postcode_software_address_list {
        position: absolute;
        max-height: 200px;
        overflow-y: scroll;
        width: 100%;
        z-index: 999;
        background: ${vars.grey_100};
        color: ${vars.black};
        padding: 0.5rem 15px;

        .gravityform__postcode_software_address_list_item {
          cursor: pointer;
          padding: 0.5rem 0;
          &:hover {
            color: ${vars.primary};
          }
        }
      }
      .gravityform__postcode_software_error {
        color: ${vars.red};
        margin-top: 0.5rem;
      }
    }

    .gravityform__error_message {
      /* position: absolute; */
      color: ${vars.red};
      margin-top: 10px;
      /* left: 15px;
      bottom: -20px; */
    }

    .gravityform__error_inform {
      color: ${vars.red};
      text-align: center;
    }

    .gravityform__field__hidden {
      display: none;
    }

    .hidden-label {
      label {
        display: none;
      }
    }
  }

  .gform_confirmation_message {
    text-align: center;
  }

  .gravityform__postcode_software_selected_address {
    padding: 1.5rem;
    color: ${vars.black};
    background: ${vars.grey_100};
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .gravityform__postcode_software_button_remove_address {
    background: ${vars.red};
    color: ${vars.white};
    text-decoration: none;
    cursor: pointer;
    text-align: center;
    line-height: 1;
    border: none;
    transition: ease-in-out 200ms;
    padding: 5px 10px;
    margin-left: 15px;

    &:hover {
      background: ${lighten(0.05, "crimson")};
    }
  }
`
