import React, { useState, useEffect, useRef } from "react"
import { debounce } from "lodash-es"
import { Col, Container, Row } from "reactstrap"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import vars from "styles/variables"
import { useNav } from "hooks/useNav"
import { convertToInternalUrl } from "utils/globalUtil"
import { navContentVariant } from "variants/navContentVariant"

function getSubNavData(data, navState) {
  const filteredData =
    data?.items?.filter(x => x.node.slug === navState.activeSlug) || []

  return filteredData[0]?.node?.childItems?.nodes || []
}

function getThirdTierData(data, subNavSlug) {
  const filteredData = data?.filter(x => x.slug === subNavSlug) || []
  return filteredData[0]?.childItems?.nodes || []
}

function SubNav({ data }) {
  const componentIsMounted = useRef(true)
  const [subNavSlug, setSubNavSlug] = useState()
  const [navImage, setNavImage] = useState()
  const [navText, setNavText] = useState()
  const { navState, closeSubNav } = useNav()

  const subNavData = getSubNavData(data, navState)
  const thirdTierData = getThirdTierData(subNavData, subNavSlug)

  const debounceSubNavHover = debounce(e => {
    if (componentIsMounted.current) {
      setSubNavSlug(e.target.dataset.slug)
      setNavText(null)
      setNavImage(null)
    }
  }, 300)

  const handleSubNavHover = e => {
    e.persist()
    debounceSubNavHover(e)
  }

  const handleThirdTierItemHover = item => {
    setNavText(item.mainNavOptions?.text || null)
    setNavImage(item.mainNavOptions?.image || null)
  }

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  return (
    <StyledSubNav
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
      }}
    >
      <Container>
        <Row>
          <Col className="offset-lg-4">
            <Row>
              <Col xs="auto">
                <StyledSubNavContent>
                  {subNavData.map((item, index) => (
                    <motion.div
                      key={item.slug}
                      initial={{ opacity: 0, x: -30 }}
                      animate={{
                        opacity: 1,
                        x: 0,
                        transition: {
                          delay: index / 8 + 0.3,
                        },
                      }}
                    >
                      <Link
                        to={convertToInternalUrl(item.url)}
                        data-slug={item.slug}
                        onMouseOver={handleSubNavHover}
                        onClick={closeSubNav}
                        className={
                          subNavSlug === item.slug ? "active" : undefined
                        }
                      >
                        {item.label}
                      </Link>
                    </motion.div>
                  ))}
                </StyledSubNavContent>
              </Col>
              <Col>
                {thirdTierData.length > 0 && (
                  <Row>
                    <Col>
                      <StyledThirdTierContent>
                        {thirdTierData.map((item, index) => (
                          <motion.div
                            key={item.slug}
                            initial={{ opacity: 0, x: -30 }}
                            animate={{
                              opacity: 1,
                              x: 0,
                              transition: {
                                delay: index / 8,
                              },
                            }}
                          >
                            <Link
                              to={convertToInternalUrl(item.url)}
                              onClick={closeSubNav}
                              onMouseOver={() => handleThirdTierItemHover(item)}
                            >
                              <img
                                src={require("assets/icons/arrow.svg").default}
                                alt=""
                                className="arrow-icon"
                              />
                              {item.label}
                            </Link>
                          </motion.div>
                        ))}
                      </StyledThirdTierContent>
                    </Col>
                    <Col>
                      <AnimatePresence>
                        {navImage && (
                          <motion.img
                            src={
                              navImage.mediaDetails?.sizes?.find(
                                x => x.name === "medium"
                              )?.sourceUrl || navImage.sourceUrl
                            }
                            alt={navImage.altText || ""}
                            className="img-fluid mb-2"
                            key="navDataImage"
                            variants={navContentVariant}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                          />
                        )}
                        {navText && (
                          <motion.div
                            key="navDataText"
                            variants={navContentVariant}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                          >
                            {navText}
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledSubNav>
  )
}

export default SubNav

const StyledSubNav = styled(motion.div)`
  padding: 2rem 0;
  background: ${vars.primary};
  color: ${vars.white};
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: 9999;

  a {
    padding: 0.5rem 0;
    line-height: 1.5;
    color: ${vars.white};
    display: inline-block;
    transition: ease-in-out 200ms;
  }
`

const StyledSubNavContent = styled.div`
  border-right: 1px solid ${vars.whiteTransparent()};
  padding-right: 3rem;

  a {
    opacity: 0.5;
    &.active,
    &:hover {
      opacity: 1;
    }
  }
`
const StyledThirdTierContent = styled.div`
  a {
    display: block;
    padding-left: 30px;
    position: relative;

    &:hover {
      color: ${vars.secondary};
      .arrow-icon {
        opacity: 1;
        left: 0;
      }
    }

    .arrow-icon {
      position: absolute;
      left: -15px;
      top: 0.8rem;
      opacity: 0;
      transition: ease-in-out 500ms;
    }
  }
`
