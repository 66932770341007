export const mobileNavItemVariant = {
  initial: {
    opacity: 0,
    x: -30,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    x: -30,
    transition: {
      duration: 0.1,
    },
  },
}
