import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"
import vars from "styles/variables"
import navItemVariant from "variants/navItemVariant"
import staggerVariant from "variants/staggerVariant"
import { convertToInternalUrl } from "utils/globalUtil"
import { useNav } from "hooks/useNav"

let timeoutId = null

function MainNav({ data }) {
  const { navState, openSubNav, closeSubNav } = useNav()

  const navHover = itemSlug => {
    timeoutId = setTimeout(function () {
      openSubNav(itemSlug)
    }, 300)
  }

  const navOut = () => {
    clearTimeout(timeoutId)
  }

  return (
    <StyledNav>
      <motion.div variants={staggerVariant}>
        <div className="d-flex justify-content-between">
          {data.items.map(x => {
            const { node: item } = x
            const itemSlug =
              item.childItems?.nodes?.length > 0 ? item.slug : null
            return (
              <div key={item.slug}>
                <motion.div variants={navItemVariant}>
                  <Link
                    to={convertToInternalUrl(item.url)}
                    onMouseOver={() => navHover(itemSlug)}
                    onMouseOut={navOut}
                    onClick={closeSubNav}
                    className={
                      navState.activeSlug === item.slug ? "active" : undefined
                    }
                  >
                    {item.label}
                  </Link>
                </motion.div>
              </div>
            )
          })}
        </div>
      </motion.div>
    </StyledNav>
  )
}

export default MainNav

const StyledNav = styled.nav`
  a {
    text-align: center;
    display: block;
    color: ${vars.white};
    padding: 2rem 0;
    position: relative;

    &:hover,
    &.active {
      &:after {
        bottom: 0;
        opacity: 1;
      }
    }

    &.nav-item-large {
      &:after {
        width: 100px;
        left: calc(50% - 50px);
      }
    }

    &:after {
      content: "";
      width: 130%;
      height: 4px;
      background: ${vars.secondary};
      display: block;
      transition: ease-in-out 300ms;
      position: absolute;
      bottom: -5px;
      left: -15%;
      opacity: 0;
    }
  }
`
