import React, { useState } from "react"
import { motion } from "framer-motion"
import styled from "styled-components"
import vars from "styles/variables"
import { mobileNavVariant } from "variants/mobileNavVariant"
import MobileNavItem from "components/ui/MobileNavItem"
import { Container, Row, Col } from "reactstrap"

function MobileNav({ data, mobileNavClose }) {
  const [activeParentSlug, setActiveParentSlug] = useState()
  const [activeChildSlug, setActiveChildSlug] = useState()

  const handleParentSlug = slug => {
    const parentSlug = slug === activeParentSlug ? null : slug
    setActiveParentSlug(parentSlug)
    setActiveChildSlug(null)
  }

  const handleChildSlug = slug => {
    const childSlug = slug === activeChildSlug ? null : slug
    setActiveChildSlug(childSlug)
  }

  return (
    <StyledMobileNav
      initial="initial"
      animate="animate"
      exit="exit"
      variants={mobileNavVariant}
      className="d-md-none"
    >
      <Container>
        <Row>
          <Col>
            {/* First Nav Layer */}
            {data.items.map(({ node: parentItem }) => (
              <div key={parentItem.slug}>
                <MobileNavItem
                  item={parentItem}
                  mobileNavClose={mobileNavClose}
                  handleActiveItem={handleParentSlug}
                  activeSlug={activeParentSlug}
                />
                {/* Second Nav Layer  */}
                {parentItem.childItems.nodes &&
                  parentItem.slug === activeParentSlug && (
                    <motion.div
                      variants={mobileNavVariant}
                      className="pl-3 mobile-nav-container"
                    >
                      {parentItem.childItems.nodes.map(secondaryItem => (
                        <div key={secondaryItem.slug}>
                          <MobileNavItem
                            item={secondaryItem}
                            mobileNavClose={mobileNavClose}
                            handleActiveItem={handleChildSlug}
                            activeSlug={activeChildSlug}
                          />
                          {/* Third Nav Layer */}
                          {secondaryItem.childItems.nodes &&
                            secondaryItem.slug === activeChildSlug && (
                              <motion.div
                                variants={mobileNavVariant}
                                className="pl-3 mobile-nav-container"
                              >
                                {secondaryItem.childItems.nodes.map(
                                  tertiaryItem => (
                                    <div key={tertiaryItem.slug}>
                                      <MobileNavItem
                                        item={tertiaryItem}
                                        mobileNavClose={mobileNavClose}
                                      />
                                    </div>
                                  )
                                )}
                              </motion.div>
                            )}
                        </div>
                      ))}
                    </motion.div>
                  )}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </StyledMobileNav>
  )
}

export default MobileNav

const StyledMobileNav = styled(motion.div)`
  background: ${vars.primary};
  color: ${vars.white};
  padding: 6rem 0 2rem 0;
  height: 100vh;
  display: block;
  z-index: 350;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;

  .mobile-nav-container {
    border-top: 1px solid ${vars.whiteTransparent()};
    border-bottom: 1px solid ${vars.whiteTransparent()};
  }
`
