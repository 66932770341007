import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import useIsInViewport from "use-is-in-viewport"
import styled from "styled-components"
import { FaFacebook } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaPinterest } from "react-icons/fa"
import vars from "styles/variables"
import { convertToInternalUrl } from "utils/globalUtil"
import ContactBar from "components/ui/ContactBar"
import Image from "components/common/Image"

function Footer() {
  const [isInViewport, targetRef] = useIsInViewport()
  const data = useStaticQuery(graphql`
    query FooterQuery {
      menu: allWpMenuItem(
        filter: { menu: { node: { slug: { eq: "footer" } } } }
        sort: { order: ASC }
      ) {
        items: edges {
          node {
            label
            url
            slug: uri
          }
        }
      }
      wp {
        inspiredGlobalOptions {
          globalOptions {
            inspiredOptionsContactsPhone
            inspiredOptionsSocialFacebook
            inspiredOptionsSocialTwitter
            inspiredOptionsContactsAddress
          }
          additionalOptionsGroup {
            inspiredOptionsSocialPinterest
            footerText
            companyName
            footerImages {
              footerImage {
                altText
                sourceUrl
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const {
    inspiredOptionsContactsPhone: tel,
    inspiredOptionsSocialFacebook: facebook,
    inspiredOptionsSocialTwitter: twitter,
    inspiredOptionsContactsAddress: address,
  } = data.wp.inspiredGlobalOptions.globalOptions || {}

  const {
    inspiredOptionsSocialPinterest: pinterest,
    footerText: footerText,
    companyName: companyName,
    footerImages: footerImages,
  } = data.wp.inspiredGlobalOptions.additionalOptionsGroup || {}

  const { title } = data.site.siteMetadata || {}
  const { items: footerNav } = data.menu || []

  const currentDate = new Date()

  return (
    <StyledFooterContainer>
      <ContactBar isNotFixed={isInViewport} />
      <StyledFooter ref={targetRef}>
        <Container>
          <Row>
            <Col xs="12" lg="5">
              <Row>
                {footerNav.map(item => (
                  <Col key={item.node.slug} xs="6" className="mb-3">
                    <Link to={convertToInternalUrl(item.node.url)}>
                      {item.node.label}
                    </Link>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs="12" lg="7">
              <Row>
                <Col>
                  <strong>{title}</strong>
                  <div
                    itemScope
                    itemType="http://schema.org/ProfessionalService"
                  >
                    <div
                      itemProp="address"
                      itemType="http://schema.org/PostalAddress"
                    >
                      <div
                        className="my-1"
                        dangerouslySetInnerHTML={{ __html: address }}
                      />
                      <div className="mb-1">
                        Tel:{" "}
                        <a href={`tel:${tel}`}>
                          <span itemProp="telephone">{tel}</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mb-1">
                    &copy;{currentDate.getFullYear()} {companyName}
                  </div>
                  <div className="mb-1">{footerText}</div>
                </Col>
                <Col xs="12" lg="auto" className="mt-3 mt-lg-0">
                  <StyledSocial noGutters>
                    <Col xs="auto" className="col-lg">
                      <a href={twitter} target="_blank" rel="noreferrer">
                        <FaTwitter />
                      </a>
                    </Col>
                    <Col xs="auto" className="col-lg">
                      <a
                        href={facebook}
                        target="_blank"
                        rel="noreferrer"
                        className="mx-3 d-block"
                      >
                        <FaFacebook />
                      </a>
                    </Col>
                    <Col xs="auto" className="col-lg">
                      <a href={pinterest} target="_blank" rel="noreferrer">
                        <FaPinterest />
                      </a>
                    </Col>
                  </StyledSocial>
                </Col>
                <Col xs="12">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    {footerImages.map((item, index) => (
                      <div key={index}>
                        <Image
                          src={item.footerImage?.sourceUrl}
                          alt={item.footerImage?.altText || ""}
                        />
                      </div>
                    ))}
                  </div>
                </Col>
                <Col xs="12" className="text-right mt-3">
                  <a href="https://www.inspiredagency.co.uk">
                    <strong>Site Design:</strong> inspiredagency.co.uk
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </StyledFooter>
    </StyledFooterContainer>
  )
}

export default Footer

const StyledFooterContainer = styled.div`
  background: ${vars.primary};
  position: relative;
  padding-top: 76px;
`

const StyledFooter = styled.footer`
  color: ${vars.white};
  padding: 2rem 0;

  @media (max-width: ${vars.screen_sm_max}) {
    padding-top: 1rem;
  }

  a {
    color: ${vars.white};
    transition: ease-in-out 200ms;

    &:hover {
      color: ${vars.secondary};
    }
  }
`

const StyledSocial = styled(Row)`
  font-size: 1.2rem;
  svg {
    transition: ease-in-out 200ms;
    &:hover {
      transform: scale(1.2);
    }
  }
`
