import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { MdMenu, MdClose } from "react-icons/md"
import vars from "styles/variables"
import MainNav from "components/ui/MainNav"
import { useNav } from "hooks/useNav"
import SubNav from "components/ui/SubNav"
import MobileNav from "./MobileNav"
import { AnimatePresence } from "framer-motion"
import LockBodyScroll from "components/common/LockBodyScroll"
import MainLogo from "components/svg/MainLogo"

function Header() {
  const data = useStaticQuery(graphql`
    query MainNavQuery {
      menu: allWpMenuItem(
        filter: {
          menu: { node: { slug: { eq: "main" } } }
          parentDatabaseId: { eq: 0 }
        }
        sort: { order: ASC }
      ) {
        items: edges {
          node {
            label
            url
            slug: uri
            childItems {
              nodes {
                label
                url
                slug: uri
                childItems {
                  nodes {
                    label
                    url
                    slug: uri
                    mainNavOptions {
                      text
                      image {
                        altText
                        sourceUrl
                        mediaDetails {
                          sizes {
                            sourceUrl
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const { menu } = data
  const { navState, closeSubNav } = useNav()
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)

  const handleHeaderLeave = () => {
    if (navState.activeSlug) closeSubNav()
  }

  const handleMobileNavClose = () => {
    setIsMobileNavOpen(false)
  }

  const headerBackgroundColour = () => {
    if (navState.navFixed && !navState.activeSlug)
      return vars.primaryTransparent(0.9)
    return vars.primary
  }

  return (
    <>
      <StyledHeader
        $isFixed={navState.navFixed}
        $bgColour={headerBackgroundColour()}
        onMouseLeave={handleHeaderLeave}
        className={navState.navFixed ? "is-fixed" : undefined}
      >
        <Container>
          <Row className="align-items-center">
            <Col
              xs="auto"
              md="4"
              className="text-center text-md-left py-2 py-md-0"
            >
              <Link to="/" onClick={closeSubNav}>
                <MainLogo />
              </Link>
            </Col>
            <Col className="d-none d-md-block">
              <MainNav data={menu} />
            </Col>
            <Col className="d-md-none text-right">
              {!isMobileNavOpen && (
                <MdMenu
                  onClick={() => setIsMobileNavOpen(true)}
                  style={{ fontSize: 30 }}
                />
              )}
              {isMobileNavOpen && (
                <MdClose
                  onClick={() => setIsMobileNavOpen(false)}
                  style={{ fontSize: 30 }}
                />
              )}
            </Col>
          </Row>
        </Container>
        {navState.activeSlug && <SubNav data={menu} />}
      </StyledHeader>
      {navState.activeSlug && <StyledOverlay />}
      <AnimatePresence>
        {isMobileNavOpen && (
          <MobileNav data={menu} mobileNavClose={handleMobileNavClose} />
        )}
      </AnimatePresence>
      {(isMobileNavOpen || navState.activeSlug) && <LockBodyScroll />}
    </>
  )
}

export default Header

const StyledHeader = styled.header`
  background: ${props => props.$bgColour};
  color: ${vars.white};
  margin-bottom: 1.5rem;
  position: ${props => (props.$isFixed ? "fixed" : "relative")};
  top: 0;
  width: 100%;
  z-index: 500;
`

const StyledOverlay = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${vars.blackTransparent(0.8)};
  z-index: 300;
`
