import React from "react"
import Form from "components/common/Form"
import useLockBodyScroll from "hooks/useLockBodyScroll"

function ContactBarForm({ type, handleFormSuccess }) {
  useLockBodyScroll()
  return (
    <div className="mt-4">
      <Form data={{ type }} handleFormSuccess={handleFormSuccess} />
    </div>
  )
}

export default ContactBarForm
