// Hook to disable body scrolling. https://usehooks.com/useLockBodyScroll
import { useLayoutEffect } from "react"

//! Updated the lock body scroll hook to prevent the whole page moving as
//! the scrollbar is added / removed on the page, we have a global style to lock the body
//! which also includes adding padding to the nav if it's fixed
export default function useLockBodyScroll() {
  useLayoutEffect(() => {
    // Get original body overflow
    //const originalStyle = window.getComputedStyle(document.body).overflow
    // Prevent scrolling on mount
    //document.body.style.overflow = "hidden"
    document.body.classList.add("lock-body")
    // Re-enable scrolling when component unmounts
    return () => {
      //document.body.style.overflow = originalStyle
      document.body.classList.remove("lock-body")
    }
  }, []) // Empty array ensures effect is only run on mount and unmount
}
