import React, { useState } from "react"
import { Col, Container, Row } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"
import { MdClose } from "react-icons/md"
import styled from "styled-components"
import vars from "styles/variables"
import Button from "components/common/Button"
import ContactBarForm from "components/ui/ContactBarForm"

function ContactBar({ isNotFixed }) {
  const [showEnquiryForm, setShowEnquiryForm] = useState(false)
  const [showBrochureForm, setShowBrochureForm] = useState(false)
  const data = useStaticQuery(graphql`
    query ContactBarQuery {
      wp {
        inspiredGlobalOptions {
          globalOptions {
            inspiredOptionsContactsPhone
          }
        }
      }
    }
  `)

  const { inspiredOptionsContactsPhone: tel } =
    data.wp.inspiredGlobalOptions.globalOptions || {}

  const isFormOpen = showEnquiryForm || showBrochureForm ? true : false

  const handleFormClose = () => {
    setShowEnquiryForm(false)
    setShowBrochureForm(false)
  }

  const handleFormSuccess = () => {
    handleFormClose()
  }

  const getContactBarTitle = () => {
    if (showEnquiryForm) return "General Enquiry"
    if (showBrochureForm) return "Request a Brochure"
    return "Enquire Now"
  }

  return (
    <StyledContactBar isNotFixed={isNotFixed} isFormOpen={isFormOpen}>
      <Container className="h-100">
        <Row className="align-items-center h-100">
          <Col>
            <Row className="align-items-center">
              <Col className="contact-bar-title d-md-none d-lg-block">
                <StyledTitle>{getContactBarTitle()}</StyledTitle>
              </Col>
              {isFormOpen && (
                <Col xs="auto">
                  <StyledCloseIcon>
                    <MdClose onClick={handleFormClose} />
                  </StyledCloseIcon>
                </Col>
              )}
              {!isFormOpen && (
                <Col xs="12" md="auto">
                  <Row>
                    <Col xs="4">
                      <Button
                        onClick={() =>
                          setShowEnquiryForm(() => !showEnquiryForm)
                        }
                      >
                        <span className="d-none d-sm-block">
                          General Enquiry
                        </span>
                        <span className="d-block d-sm-none">Enquire</span>
                      </Button>
                    </Col>
                    <Col xs="4">
                      <Button
                        onClick={() =>
                          setShowBrochureForm(() => !showBrochureForm)
                        }
                      >
                        <span className="d-none d-md-block">
                          Request a Brochure
                        </span>
                        <span className="d-block d-md-none">Brochure</span>
                      </Button>
                    </Col>
                    <Col xs="4">
                      <Button to={`tel:${tel}`}>Call Us</Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {showEnquiryForm && (
              <ContactBarForm type={1} handleFormSuccess={handleFormSuccess} />
            )}
            {showBrochureForm && (
              <ContactBarForm type={2} handleFormSuccess={handleFormSuccess} />
            )}
          </Col>
        </Row>
      </Container>
    </StyledContactBar>
  )
}

export default ContactBar

const StyledContactBar = styled.div`
  position: ${props =>
    props.isNotFixed && !props.isFormOpen ? "absolute" : "fixed"};
  background: ${vars.primary};
  color: ${vars.white};
  ${props => !props.isNotFixed && "bottom: 0;"};
  ${props => props.isNotFixed && "top: 0;"};
  width: 100%;
  padding: 1rem 0;
  z-index: 600;
  left: 0;
  height: ${props => (props.isFormOpen ? "100vh" : "auto")};
  align-items: center;
  ${props => props.isFormOpen && "overflow-y: scroll;"}

  @media (max-width: ${vars.screen_sm_max}) {
    a,
    button,
    .react-ripples {
      min-width: 100%;
      width: 100%;
      display: block;
    }
  }

  .gform_wrapper {
    margin-left: -15px;
    margin-right: -15px;
  }

  .contact-bar-title{
    @media (max-width: ${vars.screen_sm_max}) {
      ${props => !props.isFormOpen && "display: none;"}
    }
  }
`
const StyledTitle = styled.span`
  font-size: 1.5rem;
`
const StyledCloseIcon = styled.div`
  height: 32px;
  font-size: 2rem;
  cursor: pointer;
`
