import { decode } from "he"

export function convertCmsDomainToLiveDomain(url) {
  return url
    .replace("https://cms", "https://www")
    .replace("http://cms", "http://www") // Once we setup SSL for the CMS we can remove the http replace
}

export function convertToInternalUrl(url) {
  if (url) {
    return url
      .replace("http://", "")
      .replace("https://", "")
      .replace("cmsthwc.inspired-digital.co.uk", "") //! Remove dev CMS url
      .replace("cms.theheritagewindowcompany.co.uk", "") //! Remove live CMS url
      .replace(process.env.GATSBY_WP_DOMAIN, "")
  }

  return url
}

export function removeCmsDomain(url) {
  if (url) {
    return url
      .replace("http://cmsthwc.inspired-digital.co.uk", "")
      .replace("http://cms.theheritagewindowcompany.co.uk", "")
      .replace("https://cmsthwc.inspired-digital.co.uk", "")
      .replace("https://cms.theheritagewindowcompany.co.uk", "")
  }

  return url
}

export function removeTrailingSlash(text) {
  return text.replace(/\/$/, "")
}

export function withoutCategory(categories, value) {
  return categories.filter(function (e) {
    return e.Name !== value
  })
}

// Function to add trailing slash if text does not have one
export function addTrailingSlash(text) {
  const lastChar = text.slice(-1)

  if (lastChar === "/") return text

  return `${text}/`
}

// A function to convert ascii codes to their string equivalent
export function convertAsciiCodes(value) {
  if (!value) return value
  return decode(value)
}
